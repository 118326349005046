var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.device + "-event",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_en.jpg")
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "companyBlock",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("投资者关系")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("INVESTORS")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("投資者關係")]) : _vm._e()]), _c("div", {
    staticClass: "companyInfo clearfix"
  }, [_c("ul", {
    staticClass: "tMenuLeft",
    class: _vm.language == 2 ? "tMenuLeftEn" : ""
  }, [_vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 1 || !_vm.language ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.name)), _c("em", [_vm._v(">")])])]) : _vm._e();
  }), _vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 2 ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameEn)), _c("em", [_vm._v(">")])])]) : _vm._e();
  }), _vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 3 ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameTr)), _c("em", [_vm._v(">")])])]) : _vm._e();
  })], 2), _c("div", {
    staticClass: "comContentRight",
    class: _vm.language == 2 ? "comContentRightEn" : ""
  }, [_vm.language == 1 ? _c("ul", [_c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("注册办事处")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.zcbsc)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("中国总办事处及主要营业地点")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.zgzbscyedd)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("香港主要营业地点")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.xgzyydd)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("联席公司秘书")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.lianxigongshi)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("授权代表")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.shouquandaibiao)
    }
  })])]) : _vm._e(), _vm.language == 2 ? _c("ul", [_c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("Registered Office")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.zcbsc)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("Head Office and Principal Place of Business in the PRC")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.zgzbscyedd)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("Principal Place of Business in Hong Kong")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.xgzyydd)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("Joint Company Secretary")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.lianxigongshi)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("Authorized Representatives")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.shouquandaibiao)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("Compliance Adviser")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.heguiguwen)
    }
  })])]) : _vm._e(), _vm.language == 3 ? _c("ul", [_c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("註冊辦事處")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.zcbsc)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("中國總辦事處及主要營業地點")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.zgzbscyedd)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("香港主要營業地點")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.xgzyydd)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("聯席公司秘書")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.lianxigongshi)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("授權代表")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.shouquandaibiao)
    }
  })]), _c("li", [_c("p", {
    staticClass: "title"
  }, [_vm._v("合規顧問")]), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.companyInfo.heguiguwen)
    }
  })])]) : _vm._e()])])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };